<template>
  <!-- 重构 - 结算管理 - 生成结算单（付款） -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goReturn()">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >生成结算单（付款）</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="180px"
          class="demo-ruleForm"
          ><el-button
            type="primary"
            class="bgc-bv ckbjmx"
            size="small"
            round
            @click="seeClassEetails()"
            >查看课程明细</el-button
          ><el-form-item label="结算信息" class="ts"></el-form-item>
          <el-form-item label="选择合同" prop="contractId" class="tsWidth">
            <el-select
              v-model="ruleForm.contractId"
              size="small"
              placeholder="请选择合同"
              ><el-option
                v-for="item in fdNewContractLists"
                :key="item.contractId"
                :label="item.contractName"
                :value="item.contractId"
              ></el-option>
            </el-select>
            <el-button type="text" @click="previewContract">预览合同</el-button>
            <span style="color: red; width: 600px"
              >结算标准:{{ chargeMessage }}</span
            >
          </el-form-item>
          <el-form-item label="班级明细" class="btbzWitch">
            <el-table
              :data="ruleForm.fdNewProjectBOList"
              :highlight-current-row="true"
              size="small"
              tooltip-effect="dark"
              max-height="850"
              style="width: 100%"
              stripe
              class="tb-edit"
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              ></el-table-column>
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="收款结算单号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                width="200"
              ></el-table-column>
              <el-table-column
                label="班级单价"
                align="left"
                show-overflow-tooltip
                prop="projectPrice"
                width="100"
              ></el-table-column>
              <el-table-column
                label="已支付课程费"
                align="left"
                show-overflow-tooltip
                prop="courseFeePaid"
                width="100"
              >
              </el-table-column>
              <el-table-column
                label="结算金额"
                align="left"
                show-overflow-tooltip
                prop="retainedAmount"
                width="100"
              ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.retainedAmount"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.retainedAmount = zF.negativeTurnjust1(
                        scope.row.retainedAmount
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.retainedAmount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结清状态"
                align="left"
                show-overflow-tooltip
                prop="settleState"
                min-width="120"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.settleState"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <span>{{
                    scope.row.settleState ? "已结清" : "未结清"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="显示" align="center" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.displayState" active-color="#13ce66" inactive-color="#ff4949"
                    :active-value="true" :inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="到账比例"
                align="left"
                show-overflow-tooltip
                prop="receiptProportion"
                width="100"
              >
                <template slot-scope="scope">
                    <el-input
                      size="small"
                      v-model="scope.row.receiptProportion"
                      placeholder="请输入"
                      :class="{'red':scope.row.receiptProportion == 0}"
                      @keyup.native="scope.row.receiptProportion = zF.oninput2(
                          scope.row.receiptProportion,
                          2
                        )"
                      @blur="algorithm(scope.$index, 'list')"
                    ></el-input>
                    <span :class="{'red':scope.row.receiptProportion == 0}">{{ scope.row.receiptProportion?scope.row.receiptProportion+'%': '0%' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="国家补贴标准(元)"
                align="left"
                show-overflow-tooltip
                prop="countrySubsidyStandard"
                min-width="120"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.countrySubsidyStandard"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.countrySubsidyStandard = zF.negativeTurnjust1(
                        scope.row.countrySubsidyStandard
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.countrySubsidyStandard }}</span>
                </template></el-table-column
              >
              <el-table-column
                :label="percentage ? '付款标准(%)':'付款标准(元)'"
                align="left"
                show-overflow-tooltip
                prop="paymentStandard"
                width="90"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.paymentStandard"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.paymentStandard = zF.oninput2(
                        scope.row.paymentStandard,2
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.paymentStandard }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settleNum"
                min-width="80"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.settleNum"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.settleNum = zF.negativeTurnjust1(
                        scope.row.settleNum
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.settleNum }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="总公共课时/总课时"
                align="left"
                show-overflow-tooltip
                min-width="120"
                ><template slot-scope="scope">
                  <span>{{ scope.row.publicLessonNum ||0 }}/{{ scope.row.lessonNum || 0 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课程方总课时"
                align="left"
                show-overflow-tooltip
                prop="courseProvideLessonNum"
                min-width="100"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.courseProvideLessonNum"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.courseProvideLessonNum = zF.negativeTurnjust1(
                        scope.row.courseProvideLessonNum
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.courseProvideLessonNum }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="课程方公共课时"
                align="left"
                show-overflow-tooltip
                prop="coursePublicLessonNum"
                min-width="110"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.coursePublicLessonNum"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.coursePublicLessonNum = zF.negativeTurnjust1(
                        scope.row.coursePublicLessonNum
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.coursePublicLessonNum }}</span>
                </template></el-table-column
              >
              <el-table-column
                label="线上课程服务费（元）"
                align="left"
                show-overflow-tooltip
                prop="courseMoney"
                min-width="140"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.courseMoney"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.courseMoney = zF.oninput2(
                        scope.row.courseMoney,
                        2
                      )
                    "
                    @blur="algorithm2(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.courseMoney }}</span></template
                >
              </el-table-column>
              <el-table-column
                  label="税点"
                  align="left"
                  show-overflow-tooltip
                  prop="payTaxPoint"
                  min-width="130"
              >
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.payTaxPoint"
                    placeholder="请输入"
                    @keyup.native="
                        scope.row.payTaxPoint = zF.oninput2(
                          scope.row.payTaxPoint,
                          2
                        )
                      "
                    @blur="algorithm2(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.payTaxPoint?scope.row.payTaxPoint+'%':'0%' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="平台结算金额（元）"
                align="left"
                show-overflow-tooltip
                prop="platformSettlementAmount"
                min-width="140"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.platformSettlementAmount"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.platformSettlementAmount = zF.oninput2(
                        scope.row.platformSettlementAmount,
                        2
                      )
                    "
                  ></el-input>
                  <span>{{
                    scope.row.platformSettlementAmount
                  }}</span></template
                >
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item
            label="付费标准"
            prop="chargesSettlement"
            class="sfbzWidth"
          >
            <el-input
              type="textarea"
              maxlength="1000"
              resize="none"
              placeholder="请输入收费标准"
              show-word-limit
              v-model="ruleForm.chargesSettlement"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" class="sfbzWidth">
            <el-input
              type="textarea"
              maxlength="500"
              resize="none"
              placeholder="请输入备注"
              show-word-limit
              v-model="ruleForm.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="收付款账号信息" class="ts"></el-form-item>
          <el-form-item label="付款单位" prop="payerName">
            <el-input
              v-model="ruleForm.payerName"
              placeholder="请输入付款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款单位" prop="payeeName">
            <el-input
              v-model="ruleForm.payeeName"
              placeholder="请输入收款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="payeeBank">
            <el-input
              v-model="ruleForm.payeeBank"
              placeholder="请输入开户银行"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="payeeAccount">
            <el-input
              v-model="ruleForm.payeeAccount"
              placeholder="请输入银行账号"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item class="operation">
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="goReturn()"
              >返回</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 1)"
              >预览结算单</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 2)"
              >生成结算单</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看班级明细 - loading -->
    <el-dialog
      title="查看课程明细"
      :visible.sync="dialogVisible1"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert1" style="height: 600px"></div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog
      title="预览合同"
      :visible.sync="dialogVisible3"
      width="900px"
      top="2%"
      center
      ><contractManagementAdd
        :seeTH="true"
        :seeContractId="ruleForm.contractId"
        v-if="dialogVisible3"
      />
    </el-dialog>
    <!-- 预览结算单 - loading -->
    <el-dialog
      title="预览结算单"
      :visible.sync="dialogVisible2"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert2" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import { console } from "../../../utils/helper";
export default {
  name: "singleAdministrationChildrenSecondEdit",
  components: {
    contractManagementAdd,
  },
  data() {
    return {
      // 父页面带过的参数
      fatherData: {
        id: "", // 待结算列表无id;结算单管理有id
        type: "", // 1-收款结算单;2-付款结算单
        addEdit: "", // add-待结算列表;edit-结算单管理
        refresh: false, // 返回列表页是否刷新页面
      },
      // 页面数据
      ruleForm: {
        billId: "", // 结算单id
        contractId: "", // 合同
        compId: "", // 课程提供方id
        trainTypeId: "", // 培训类型id
        eduProjectList: [],
        fdNewProjectBOList: [], // 国家补贴标准列表数据
        chargesSettlement: "", // 收费标准
        remark: "", // 备注
        payerName: "北京晟融信息技术有限公司", // 付款单位
        payeeName: "", // 收款单位
        payeeBank: "", // 开户银行
        payeeAccount: "", // 银行账号
        settlementUserCount: 0, // 潜在字段 - 结算人数
        totalLessonNum: 0, // 潜在字段 - 总课时
        openLessonNum: 0, // 潜在字段 - 总公共课时
        payerAmount: 0, // 潜在字段 - 线上课程服务费（元）
      },
      // 数据校验
      rules: {
        // 选择合同
        contractId: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
        // 收费标准
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        // 付款单位
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        // 收款单位
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        // 开户银行
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        // 银行账号
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 国家补贴标准列表 - 国家补贴标准（元）是否可以输入
      disabled1: false,
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 预览合同 - loading
      dialogVisible3: false,
      chargeMessage: "",
      options: [
        {
          value: true,
          label: "已结清",
        },
        {
          value: false,
          label: "未结清",
        },
      ],
    };
  },
  created() {
    this.fatherData.id = JSON.parse(this.$route.query.editInfo).id;
    this.fatherData.type = JSON.parse(this.$route.query.editInfo).type;
    this.fatherData.addEdit = JSON.parse(this.$route.query.editInfo).addEdit;
    this.generateSettlementDocument();
  },
  mounted() {},
  watch: {
    // 监听合同 - 变更：收费标准/付款单位
    "ruleForm.contractId": {
      handler: function (newVal) {
        // 判断合同数据
        let lData = [];
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          if (this.fdNewContractLists[i].contractId == newVal) {
            this.ruleForm.chargesSettlement = this.fdNewContractLists[i].chargesSettlement; // 结算标准
            this.ruleForm.payeeBank = this.fdNewContractLists[i].partyBBank; // 开户行
            this.ruleForm.payeeAccount =this.fdNewContractLists[i].partyBAccount; // 银行账户
            lData = this.fdNewContractLists[i];
            if (lData.settleStandardType == "10") {
              this.disabled1 = false;
            } else {
              this.disabled1 = true;
            }
          }
        }
        // 判断国家补贴标准-add:算初始值/edit:不算初始值
        let htData = JSON.parse(lData.settleContent);
        if (this.fatherData.addEdit == "add") {
          for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
            if (Object.values(htData).length > 0) {

              this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "payTaxPoint",
                  lData.payTaxPoint
              );
              if (lData.settleStandardType == "10") {
                this.percentage = true
                this.ruleForm.fdNewProjectBOList[i].paymentStandard =
                  htData.fkcoursesRatio;
                this.chargeMessage =
                  "按国家补贴标准比例" +
                  ",课程比例" +
                  htData.fkcoursesRatio +
                  "%";
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard &&
                  this.ruleForm.fdNewProjectBOList[i].settleNum &&
                  this.ruleForm.fdNewProjectBOList[i].receiptProportion
                ) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = (
                    (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                    (Number(htData.fkcoursesRatio) / 100) *
                    Number(this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard) *
                    Number(this.ruleForm.fdNewProjectBOList[i].settleNum) *
                    ((Number(this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum) -Number(this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum)) /
                    Number(this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum))/* 140次迭代做标记，这里去掉了.toFixed(2)*/
                  ).toFixed(2);
                // courseProvideLessonNum:总课时
                // receiptProportion:到账比例
                // fkcoursesRatio:课程比例
                // countrySubsidyStandard:国家补贴标准（元）
                // settleNum:结算人数
                // coursePublicLessonNum:公共课时
                // payTaxPoint:税点
                // platformSettlementAmount:平台结算金额（元）
                // 线上课程服务费 = ((到账比例 / 100) * (课程比例 / 100) * 国家补贴标准 * 结算人数 * ((总课时 - 公共课时) / 总课时))


                  let algorithm = (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                  (Number(htData.fkcoursesRatio) / 100) *
                      Number(this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum) *
                      ((Number(this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum) -Number(this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum)) /
                          Number(this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum)
                  ).toFixed(2)

                  if(lData.payTaxPoint&&lData.payTaxPoint>0){
                    // 如果有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm*((100-lData.payTaxPoint)/100)
                        ).toFixed(2);
                  }else{
                    // 如果没有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm
                        ).toFixed(2);
                  }

                } else {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = 0;
                  this.ruleForm.fdNewProjectBOList[
                    i
                  ].platformSettlementAmount = 0;
                }
              }
              if (lData.settleStandardType == "20") {
                this.ruleForm.fdNewProjectBOList[i].paymentStandard = htData.fkchargePerson;/* 140次迭代做标记，添加了这行代码*/
                 this.percentage = false
                this.chargeMessage =
                  "按人数收费" + ",每人收费" + htData.fkchargePerson + "元";
                if (this.ruleForm.fdNewProjectBOList[i].settleNum&&this.ruleForm.fdNewProjectBOList[i].receiptProportion) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = (
                    (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                    Number(htData.fkchargePerson) *
                    Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                  )/* 140次迭代做标记，这里去掉了.toFixed(2)*/;
                  let algorithm = (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) * 
                      Number(htData.fkchargePerson) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                  if(lData.payTaxPoint&&lData.payTaxPoint>0){
                    // 如果有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm*((100-lData.payTaxPoint)/100)
                        ).toFixed(2);
                  }else{
                    // 如果没有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm
                        ).toFixed(2);
                  }

                } else {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = 0;
                  this.ruleForm.fdNewProjectBOList[
                    i
                  ].platformSettlementAmount = 0;
                }
              }
              if (lData.settleStandardType == "30") {
                this.ruleForm.fdNewProjectBOList[i].paymentStandard = htData.fkfixedCost;/* 140次迭代做标记，添加了这行代码*/
                this.percentage = false
                if (this.ruleForm.fdNewProjectBOList[i].receiptProportion) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = (
                    (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                    Number(htData.fkfixedCost)
                  ).toFixed(2);
                } else {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = 0;
                  this.ruleForm.fdNewProjectBOList[
                    i
                  ].platformSettlementAmount = 0;
                }
                this.chargeMessage = "固定费用" + htData.fkfixedCost + "元";
              }
              if (lData.settleStandardType == "50") {
                 this.percentage = false
                this.ruleForm.fdNewProjectBOList[i].paymentStandard =
                  htData.fkCoursesPersonHour;
                this.chargeMessage =
                  "按课时" + ",每人每课时" + htData.fkCoursesPersonHour + "元";
                if (this.ruleForm.fdNewProjectBOList[i].settleNum&&this.ruleForm.fdNewProjectBOList[i].receiptProportion) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = (
                    (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                    Number(htData.fkCoursesPersonHour) *
                    Number(this.ruleForm.fdNewProjectBOList[i].settleNum) *
                    Number(
                      this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum
                    )
                  ).toFixed(2);
                  let algorithm = (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                      Number(htData.fkCoursesPersonHour) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum) *
                      Number(
                          this.ruleForm.fdNewProjectBOList[i]
                              .courseProvideLessonNum
                      )
                  if(lData.payTaxPoint&&lData.payTaxPoint>0){
                    // 如果有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm*((100-lData.payTaxPoint)/100)
                        ).toFixed(2);
                  }else{
                    // 如果没有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm
                        ).toFixed(2);
                  }
                } else {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = 0;
                  this.ruleForm.fdNewProjectBOList[
                    i
                  ].platformSettlementAmount = 0;
                }
              }
              if (lData.settleStandardType == "60") {
                 this.percentage = true
                this.ruleForm.fdNewProjectBOList[i].paymentStandard =
                  htData.fkCoursesSSRatio;
                this.chargeMessage =
                  "按实收比例" + ",课程比例" + htData.fkCoursesSSRatio + "%";
                if (this.ruleForm.fdNewProjectBOList[i].settleNum&&this.ruleForm.fdNewProjectBOList[i].receiptProportion) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = (
                    (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                    Number(htData.fkCoursesSSRatio / 100) *
                    Number(
                      this.ruleForm.fdNewProjectBOList[i].retainedAmount
                    )
                  ).toFixed(2);
                  let algorithm = (Number(this.ruleForm.fdNewProjectBOList[i].receiptProportion) / 100) *
                      Number(htData.fkCoursesSSRatio / 100) *
                      Number(
                          this.ruleForm.fdNewProjectBOList[i].retainedAmount
                      )
                  if(lData.payTaxPoint&&lData.payTaxPoint>0){
                    // 如果有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm*((100-lData.payTaxPoint)/100)
                        ).toFixed(2);
                  }else{
                    // 如果没有税点
                    this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                        (
                            algorithm
                        ).toFixed(2);
                  }
                } else {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = 0;
                  this.ruleForm.fdNewProjectBOList[
                    i
                  ].platformSettlementAmount = 0;
                }
              }
            } else {
              this.ruleForm.fdNewProjectBOList[i].courseMoney = "";
              this.$message({
                message:
                  "该“" +
                  lData.contractName +
                  "”的合同结算标准有误，请联系管理员!",
                type: "warning",
              });
              break;
            }
          }
        } else {
          for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
            if (Object.values(htData).length > 0) {
              if (lData.settleStandardType == "10") {
                this.chargeMessage =
                  "按国家补贴标准比例" +
                  ",课程比例" +
                  htData.fkcoursesRatio +
                  "%";
              }
              if (lData.settleStandardType == "20") {
                 this.percentage = false
                this.chargeMessage =
                  "按人数收费" + ",每人收费" + htData.fkchargePerson + "元";
                
              }
              if (lData.settleStandardType == "30") {
                 this.percentage = false
                this.ruleForm.fdNewProjectBOList[i].courseMoney = Number(
                  htData.fkfixedCost
                );
                this.chargeMessage = "固定费用" + htData.fkfixedCost + "元";
              }
              if (lData.settleStandardType == "50") {
                 this.percentage = false
                this.chargeMessage =
                  "按课时" + ",每人每课时" + htData.fkCoursesPersonHour + "元";
               
              }
              if (lData.settleStandardType == "60") {
                 this.percentage = true
                this.chargeMessage =
                  "按实收比例" + ",课程比例" + htData.fkCoursesSSRatio + "%";
               
              }
            } else {
              this.ruleForm.fdNewProjectBOList[i].courseMoney = "";
              this.$message({
                message:
                  "该“" +
                  lData.contractName +
                  "”的合同结算标准有误，请联系管理员!",
                type: "warning",
              });
              break;
            }
          }
        }
      },
    },
  },
  methods: {
    // 面包屑 - 返回上个页面
    goReturn() {
      this.$router.push({
        path:
          this.fatherData.addEdit == "edit"
            ? "/web/financialSettlement/settlement/singleAdministration"
            : "/web/financialSettlement/settlement/toBeSettledList",
        query: {
          active: this.fatherData.type,
          refresh: true,
          multipleTable:
            this.fatherData.addEdit == "edit" ? "" : "multipleTable",
        },
      });
    },
    // 预览合同
    previewContract() {
      if (this.ruleForm.contractId) {
        this.dialogVisible3 = true;
      } else {
        this.$message.error("请选择合同");
      }
    },
    // 预览 - 查看班级明细
    seeClassEetails() {
      let projectCourses = [];
      for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
        projectCourses.push({
          projectCourseId: this.ruleForm.fdNewProjectBOList[i].projectCourseId,
          settleNum: this.ruleForm.fdNewProjectBOList[i].settleNum,
          courseMoney: this.ruleForm.fdNewProjectBOList[i].courseMoney,
          platformSettlementAmount:this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount,
          countrySubsidyStandard:this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard,
          paymentStandard:this.ruleForm.fdNewProjectBOList[i].paymentStandard,
          projectId:this.ruleForm.fdNewProjectBOList[i].projectId,
          billId:this.ruleForm.fdNewProjectBOList[i].billId,
          displayState:this.ruleForm.fdNewProjectBOList[i].displayState,
        });
      }
      this.$post("/biz/new/bill/pay/previewCourseDetails", {
        compOwnerId: this.ruleForm.compId,
        contractId:this.ruleForm.contractId,
        projectCourses,
      })
        .then((res) => {
          this.dialogVisible1 = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert1");
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 获取结算单数据
    generateSettlementDocument() {
      if (this.fatherData.addEdit == "add") {
        this.$post("/biz/new/bill/pay/generatePayStatement", {
          selectedNodes: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.compId = res.data.compId; // 课程提供方id
              this.ruleForm.trainTypeId = res.data.trainTypeId; // 培训类型
              this.ruleForm.fdNewProjectBOList = res.data.projectCourseList; // 国家补贴标准数据
              this.ruleForm.chargesSettlement = res.data.chargesSettlement; // 结算标准
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum =
                    "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseMoney == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = "";
                }
                if (!this.ruleForm.fdNewProjectBOList[i].settleState) {//结算状态默认为已结清
                  this.$set(this.ruleForm.fdNewProjectBOList[i],'settleState', true)
                }
                this.$set(this.ruleForm.fdNewProjectBOList[i],'paymentStandard', '')
                this.$set(this.ruleForm.fdNewProjectBOList[i],'retainedAmount', this.ruleForm.fdNewProjectBOList[i].actualReceivedFee)//新增留存金额等于结算金额
              }
              this.ruleForm.eduProjectList = res.data.eduProjectList; // 结算班级列表 怎么来的怎么给回去
              if (res.data.contractList.length) {
                this.fdNewContractLists = res.data.contractList; // 合同数据
                this.ruleForm.contractId = res.data.contractList[0].contractId; // 默认赋值合同
              }
              this.ruleForm.payeeName = res.data.payeeName; // 收款单位
            }
          })
          .catch(() => {
            return;
          });
      }
      if (this.fatherData.addEdit == "edit") {
        this.$post("/biz/new/bill/pay/getInfo", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.compId = res.data.compId; // 课程提供方id
              this.ruleForm.trainTypeId = res.data.trainTypeId; // 培训类型
              this.ruleForm.fdNewProjectBOList = res.data.projectCourseList; // 国家补贴标准数据
              // this.ruleForm.chargesSettlement = res.data.chargesSettlement; // 结算标准
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].courseProvideLessonNum =
                    "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].coursePublicLessonNum =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseMoney == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseMoney = "";
                }
                // this.$set(
                //   this.ruleForm.fdNewProjectBOList[i],
                //   "paymentStandard",
                //   this.ruleForm.fdNewProjectBOList[i].paymentStandard
                // );
              }
              this.ruleForm.eduProjectList = res.data.eduProjectList; // 结算班级列表 怎么来的怎么给回去
              if (res.data.contractList.length) {
                this.fdNewContractLists = res.data.contractList; // 合同数据
                this.ruleForm.contractId = res.data.contractId || ""; // 默认赋值合同
                // 更改合同里的结算标准 - 只是更改当前页面的，实际合同里的数据不会改变
                for(let i=0;i<res.data.contractList.length;i++){
                  if(res.data.contractList[i].contractId == res.data.contractId) {
                    res.data.contractList[i].chargesSettlement = res.data.chargesSettlement; // 结算标准
                    res.data.contractList[i].partyBBank = res.data.payeeBank; // 开户行
                    res.data.contractList[i].partyBAccount = res.data.payeeAccount; // 银行账户
                  }
                }
              }
              this.ruleForm.payeeName = res.data.payeeName; // 收款单位
              this.ruleForm.settlementUserCount = res.data.settlementUserCount; // 潜在字段 - 结算人数
              this.ruleForm.totalLessonNum = res.data.totalLessonNum; // 潜在字段 - 总课时
              this.ruleForm.openLessonNum = res.data.openLessonNum; // 潜在字段 - openLessonNum
              this.ruleForm.payerAmount = res.data.payerAmount; // 潜在字段 - 平台服务费（元）
              this.ruleForm.remark = res.data.remark; // 潜在字段 - 平台服务费（元）
              this.ruleForm.billId = res.data.billId; // 结算单id
              this.$forceUpdate();
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 算法('列表当前行的下标','列表当前行的数据','list-列表/contract-合同')
    algorithm(index, type) {
      if (type == "list") {
        let lData = [];
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          if (
            this.fdNewContractLists[i].contractId == this.ruleForm.contractId
          ) {
            lData = this.fdNewContractLists[i];
          }
        }
        let htData = JSON.parse(lData.settleContent);
        if (lData.settleStandardType == "10") {
          if (
            this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard &&
            this.ruleForm.fdNewProjectBOList[index].settleNum
          ) {
            console.log(
              (Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) /
                100),
              Number(
                this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard
              ),
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum),
                Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                ),
                  Number(
                    this.ruleForm.fdNewProjectBOList[index]
                      .coursePublicLessonNum
                  ),
                Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                )
            );
            this.ruleForm.fdNewProjectBOList[index].courseMoney = (
              (Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) /
                100) *
              Number(
                this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard
              ) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum) *
              (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                ) -
                  Number(
                    this.ruleForm.fdNewProjectBOList[index]
                      .coursePublicLessonNum
                  )) /
                Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                )
              )/* 140次迭代做标记，这里去掉了.toFixed(2)*/
            ).toFixed(2);
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
              (Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) /
                100) *
              Number(
                this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard
              ) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum) *
              (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                ) -
                  Number(
                    this.ruleForm.fdNewProjectBOList[index]
                      .coursePublicLessonNum
                  )) /
                Number(
                  this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
                )
              )/* 140次迭代做标记，这里去掉了.toFixed(2)*/
            ).toFixed(2);
          } else {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = "";
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
              "";
          }
        }
        if (lData.settleStandardType == "20") {
          if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = (
              Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum)
            ).toFixed(2);
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
              Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum)
            ).toFixed(2);
          } else {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = "";
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
              "";
          }
        }
        if (lData.settleStandardType == "30") {
          this.ruleForm.fdNewProjectBOList[index].courseMoney = Number(
            htData.fkfixedCost
          );
          this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
            Number(htData.fkfixedCost);
        }
        if (lData.settleStandardType == "50") {
          if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = (
              Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum) *
              Number(
                this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
              )
            ).toFixed(2);
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
              Number(this.ruleForm.fdNewProjectBOList[index].paymentStandard) *
              Number(this.ruleForm.fdNewProjectBOList[index].settleNum) *
              Number(
                this.ruleForm.fdNewProjectBOList[index].courseProvideLessonNum
              )
            ).toFixed(2);
          } else {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = 0;
            this.ruleForm.fdNewProjectBOList[
              index
            ].platformSettlementAmount = 0;
          }
        }
        if (lData.settleStandardType == "60") {
          if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = (
              Number(
                this.ruleForm.fdNewProjectBOList[index].paymentStandard / 100
              ) *
              Number(this.ruleForm.fdNewProjectBOList[index].retainedAmount)
            ).toFixed(2);
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
              Number(
                this.ruleForm.fdNewProjectBOList[index].paymentStandard / 100
              ) *
              Number(this.ruleForm.fdNewProjectBOList[index].retainedAmount)
            ).toFixed(2);
          } else {
            this.ruleForm.fdNewProjectBOList[index].courseMoney = 0;
            this.ruleForm.fdNewProjectBOList[
              index
            ].platformSettlementAmount = 0;
          }
        }
        // 到账比例
        if(this.ruleForm.fdNewProjectBOList[index].receiptProportion&&this.ruleForm.fdNewProjectBOList[index].receiptProportion>0){
          this.ruleForm.fdNewProjectBOList[index].courseMoney = (
              Number(
                  this.ruleForm.fdNewProjectBOList[index].courseMoney
              ) *
              (Number(this.ruleForm.fdNewProjectBOList[index].receiptProportion) / 100)
          ).toFixed(2);
        }else{
          this.ruleForm.fdNewProjectBOList[index].courseMoney = 0
        }
        for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
          // 税点
          if(this.ruleForm.fdNewProjectBOList[index].payTaxPoint&&this.ruleForm.fdNewProjectBOList[index].payTaxPoint>0){
            // 如果有税点
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
                Number(
                    this.ruleForm.fdNewProjectBOList[index].courseMoney
                ) *
                Number(
                    (100 - this.ruleForm.fdNewProjectBOList[index].payTaxPoint) / 100
                )
            ).toFixed(2);
          }else{
            // 如果没有税点
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
                Number(
                    this.ruleForm.fdNewProjectBOList[index].courseMoney
                )
            ).toFixed(2);
          }
          
        }
      }
    },
    algorithm2(index, type) {
      if (type == "list") {
        let lData = [];
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          if (
              this.fdNewContractLists[i].contractId == this.ruleForm.contractId
          ) {
            lData = this.fdNewContractLists[i];
          }
        }
        let htData = JSON.parse(lData.settleContent);
        for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
          if(this.ruleForm.fdNewProjectBOList[index].payTaxPoint&&this.ruleForm.fdNewProjectBOList[index].payTaxPoint>0){
            // 如果有税点
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
                Number(
                    this.ruleForm.fdNewProjectBOList[index].courseMoney
                ) *
                Number(
                    (100 - this.ruleForm.fdNewProjectBOList[index].payTaxPoint) / 100
                )
            ).toFixed(2);
          }else{
            // 如果没有税点
            this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
                Number(
                    this.ruleForm.fdNewProjectBOList[index].courseMoney
                )
            ).toFixed(2);
          }
        }
      }
    },
    // 生成/预览 - 结算单按钮;1-预览/2-生成
    generatePreview(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hData = {
            contractId: this.ruleForm.contractId, // 合同
            trainTypeId: this.ruleForm.trainTypeId, // 培训类型id
            chargesSettlement: this.ruleForm.chargesSettlement, // 收费标准
            eduProjectList: this.ruleForm.eduProjectList,
            remark: this.ruleForm.remark, // 备注
            payerName: this.ruleForm.payerName, // 付款单位
            payeeName: this.ruleForm.payeeName, // 收款单位
            payeeBank: this.ruleForm.payeeBank, // 开户银行
            payeeAccount: this.ruleForm.payeeAccount, // 银行账号
          };
          if (this.ruleForm.billId) {
            hData.billId = this.ruleForm.billId;
          }
          let gjbtbzData = [];
          this.ruleForm.fdNewProjectBOList.forEach((item) => {
            gjbtbzData.push(Object.assign({}, item));
          });
          let settlementUserCounts = 0;
          let totalLessonNums = 0;
          let openLessonNums = 0;
          let payerAmount = 0;
          for (let i = 0; i < gjbtbzData.length; i++) {
            if (!gjbtbzData[i].countrySubsidyStandard) {
              gjbtbzData[i].countrySubsidyStandard = 0;
            }
            if (!gjbtbzData[i].settleNum) {
              gjbtbzData[i].settleNum = 0;
            }
            if (!gjbtbzData[i].courseProvideLessonNum) {
              gjbtbzData[i].courseProvideLessonNum = 0;
            }
            if (!gjbtbzData[i].coursePublicLessonNum) {
              gjbtbzData[i].coursePublicLessonNum = 0;
            }
            if (!gjbtbzData[i].courseMoney) {
              gjbtbzData[i].courseMoney = 0;
            }
            settlementUserCounts += Number(gjbtbzData[i].settleNum);
            totalLessonNums += Number(gjbtbzData[i].courseProvideLessonNum);
            openLessonNums += Number(gjbtbzData[i].coursePublicLessonNum);
            payerAmount += Number(gjbtbzData[i].courseMoney);
          }
          hData.projectCourseList = gjbtbzData; // 整理好的国家补贴标准列表
          hData.settlementUserCount = settlementUserCounts; // 潜在字段 - 结算人数
          hData.totalLessonNum = totalLessonNums; // 潜在字段 - 总课时
          hData.openLessonNum = openLessonNums; // 潜在字段 - 总公共课时
          hData.payerAmount = payerAmount.toFixed(2); // 潜在字段 - 平台服务费（元）
          if (type == 1) {
            this.$post("/biz/new/bill/pay/previewPayStatement", hData)
              .then((ret) => {
                this.dialogVisible2 = true;
                this.$nextTick(() => {
                  pdf.embed(ret.data, "#pdf-cert2");
                });
              })
              .catch((err) => {
                return;
              });
          } else {
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/bill/pay/savePayStatement", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch((err) => {
                  return;
                });
            } else {
              this.$post("/biz/new/bill/pay/modify", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.goReturn();
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                  }
                })
                .catch((err) => {
                  return;
                });
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="less">
.demo-ruleForm {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    min-width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 550px;
    .el-input {
      width: 280px;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      width: 100px;
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
  .tb-edit .el-select {
    display: none;
  }

  .tb-edit .current-row .el-select {
    display: block;
  }

  .tb-edit .current-row .el-select + span {
    display: none;
  }
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
span.red,.red .el-input__inner{
  color: red;
}
</style>